import { ParsedUrlQuery } from "querystring";
import { ReadonlyURLSearchParams } from "next/navigation";

/**
 * pathから特定のクエリパラメータを除去する
 */
export const removeParameter = (path: string, param: string): string => {
  const regexp = new RegExp("([?|&])" + param + "=(.*?)&", "g");
  const ret = (path + "&")
    .replace(regexp, "$1")
    .replace(/&&/, "&")
    .replace(/&$/, "")
    .replace(/\?$/, "");

  //後続のパラメータがある場合
  return ret;
};

export const removeParameters = (path: string, params: string[]): string => {
  return params.reduce((pre, cur) => {
    return removeParameter(pre, cur);
  }, path);
};

export const removeAllParameters = (path: string): string => {
  const regexp = new RegExp("[?].*", "g");
  return path.replace(regexp, "");
};

export const addParameter = (path: string, param: string, value: string): string => {
  if (path.includes("?")) {
    return path + "&" + param + "=" + value;
  } else {
    return path + "?" + param + "=" + value;
  }
};

export const convertSearchParamsToQuery = (
  searchParams: URLSearchParams | ReadonlyURLSearchParams,
): ParsedUrlQuery => {
  const query: ParsedUrlQuery = {};

  searchParams.forEach((value, key) => {
    const existingValue = query[key];
    if (Array.isArray(existingValue)) {
      existingValue.push(value);
    } else if (existingValue) {
      query[key] = [existingValue, value];
    } else {
      query[key] = value;
    }
  });

  return query;
};

export const getQueryStrings = (searchParams: { [key: string]: string | string[] | undefined }) => {
  const pathParams = new URLSearchParams();
  Object.entries(searchParams).forEach(([key, value]) => {
    if (value !== undefined) {
      pathParams.set(key, value.toString());
    }
  });
  const queryString = pathParams.toString();
  return queryString;
};
